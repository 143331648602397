<template>
  <el-dialog
   :close-on-click-modal='false'
   :close-on-press-escape='false'
   :destroy-on-close='true'
   :show-close='false'
    width="45%" :title="title" :visible.sync="isShow">
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>工程名称：</div>
        <el-input v-model="projectName"  @input="changeInput" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>工程类型：</div>
        <el-input  @input="changeInput" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label">工程编号：</div>
        <el-input  @input="changeInput" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>所属单位：</div>
        <el-input  @input="changeInput" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label">工程主管：</div>
        <el-input  @input="changeInput" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label">验收人：</div>
        <el-input  @input="changeInput" class="mini"></el-input>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>地理位置：</div>
        <div @click="openMap" class="big map">{{ address || '选择地图' }}</div>
      </div>
      <div class="item-input">
        <div class="label">备注信息：</div>
        <el-input type="textarea"
          :rows="2"  @input="changeInput" class="big"></el-input>
      </div>
    </div>

    <select-map :localChange='onCallbackMarker' ref='map'></select-map>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import selectMap from '@/components/selectMap'
export default {
  name: 'addProject',
  props: {
    title: {
      type: String,
      default() {
        return '添加工程'
      },
    },
  },
  components: {
    selectMap,
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      projectName: '',
      address: '',
    }
  },
  methods: {
    open: function () {
      this.isShow = true
    },
    close: function () {
      this.isShow = false
    },
    submit: function() {
      console.log(this.projectName, ' 这是工程民初')
    },
    changeInput: function (e) {
      this.$forceUpdate()
    },

    openMap: function() {
      this.$refs.map.open()
    },

    // 获取当前坐标点
    onCallbackMarker: function({ address, location }) {
      console.log('地址：', address)
      console.log('经纬度：', location)
      this.address = address
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
