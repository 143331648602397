<template>
  <div >
    <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
    <TABS :tabChange='onCallbackTab' :list="tabsArr"></TABS>
    <TABLE :selectChange='onCallbackSelect' :buttonChange='onCallbackButton' :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
    <add-project ref='addP' :title="title"></add-project>
    <select-people ref='selectP' :title="title"></select-people>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import addProject from './components/addProject' // 添加或编辑工程
import selectPeople from './components/selectPeople' // 绑定人员 或 授权人员
import { getProject, delProject } from '@/api/project'
export default {
  name: 'ProjectList',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    addProject,
    selectPeople,
  },
  data() {
    return {
      searchArr: [
        {
          name: '工程名称',
          type: 'input',
          options: [],
          key: 'projectName',
        },
        {
          name: '工程类型',
          type: 'picker',
          options: [],
          key: 'projectType',
        },
        {
          name: '所属单位',
          type: 'picker',
          options: [],
          key: 'projectUnit',
        },
        {
          name: '创建时间',
          type: 'date',
          key: 'createTime',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '新增工程',
          func: 'openAddProject',
          isLoading: false,
        },
        {
          type: 'primary',
          name: '导入工程',
          func: '',
          isLoading: false,

        },
        {
          type: 'primary',
          name: '下载模板',
          func: '',
          isLoading: false,

        },
        {
          type: 'primary',
          name: '导出工程',
          func: '',
          isLoading: false,

        },
        {
          type: 'danger',
          name: '批量删除',
          func: 'removeProject',
          isLoading: false,

        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'name',
          label: '工程名称',
          width: '120',
        },
        {
          prop: 'serial',
          label: '工程编号',
          width: '120',
        },
        {
          prop: 'type',
          label: '工程类型',
          width: '120',
        },
        {
          prop: 'unit.unit',
          label: '单位',
          width: '120',
        },
        {
          prop: 'unit.group',
          label: '分组',
          width: '120',
        },
        {
          prop: 'supervisor',
          label: '工程主管',
          width: '120',
        },
        {
          prop: 'binddev',
          label: '绑定设备',
          width: '120',
        },
        {
          prop: 'is_auth',
          label: '工程授权',
          width: '120',
        },
        {
          prop: 'auth',
          label: '工程人员',
          width: '120',
        },
        {
          prop: 'location',
          label: '工程位置',
          width: '120',
        },
        {
          prop: 'create_at',
          label: '创建时间',
          width: '120',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '单兵多屏',
          func: '',
        },
        {
          type: '',
          name: '绑定',
          func: 'openBind',
        },
        {
          type: '',
          name: '授权',
          func: 'openAuth',
        },
        {
          type: '',
          name: '编辑',
          func: '',
        },
        {
          type: 'danger',
          name: '删除',
          isLoading: false,
          func: 'removeProject',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        type: '',
        name: '',
        unit_id: '',
        start_at: '',
        end_at: '',
      },
      ids: '', // 要删除的id
      title: '添加工程', // 弹窗标题
      tabsIndex: 0, // tabs 按钮的索引
    }
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.unit_id = this.$store.getters.USER_ID
      getProject(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach((item) => {
          item.buttons = this.butnArr
          item.is_auth = item.is_auth ? '授权' : '未授权'
        })
        this.dataArr = result.data
      })
    },

    // 添加授权
    openAuth: function() {
      this.title = '授权人员'
      this.openSelectPeople()
    },

    // 绑定人员
    openBind: function() {
      this.title = '绑定人员'
      this.openSelectPeople()
    },

    // 打开 人员 或 授权
    openSelectPeople: function() {
      this.$refs.selectP.open()
    },

    // 打开新增工程弹窗
    openAddProject: function() {
      this.loading()
      this.$refs.addP.open()
    },

    // 新增工程
    addProject: function() {

    },

    // 删除工程
    removeProject: function() {
      if (!this.ids) {
        this.$toast('请选择要删除的工程')
        this.loading()
        return false
      }
      delProject(this.ids).then(res => {
        this.$toast(res.message)
        this._loadData()
        this.loading()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { createTime, projectUnit, projectName, projectType } = data
      this.formData.start_at = createTime[0]
      this.formData.end_at = createTime[1]
      this.formData.unit_id = projectUnit
      this.formData.name = projectName
      this.formData.type = projectType
      this._loadData()
    },
    // 收到 tabs 回调
    onCallbackTab: function({ item, index }) {
      this.loading(index)
      this[item.func]()
    },

    // 打开或关闭tabs 按钮loading
    loading: function(index) {
      index && (this.tabsIndex = index)
      this.tabsArr[this.tabsIndex].isLoading = !this.tabsArr[this.tabsIndex].isLoading
    },

    // 收到 选择 回调
    onCallbackSelect: function(ids) {
      this.ids = ids
    },
    // 收到 表格 内方法 回调
    onCallbackButton: function(item, func) {
      this.ids = item.id
      this[func]()
    },
    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
