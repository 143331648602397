<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="title"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div class="label">人员名称：</div>
        <el-input
          size="small"
          v-model="text"
          @input="changeInput"
          class="mini"
        ></el-input>
        <el-button
          style="margin-bottom: 5px"
          size="small"
          class="search"
          type="primary"
          @click="search"
          >搜索</el-button
        >
      </div>
      <div class="item-input">
        <el-button
          style="margin-bottom: 5px"
          size="small"
          class="search"
          type="warning"
          @click="resetChecked"
          >清空已选</el-button
        >
      </div>
      <div class="item-input tree">
        <div class="label"><span class="red">*</span>选择人员：</div>
        <el-tree
          class="big"
          :data="data"
          :props="defaultProps"
          node-key="id"
          default-expand-all
          :filter-node-method="filterNode"
          :show-checkbox="true"
          ref="tree"
        >
        </el-tree>
      </div>
    </div>

    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submit">确 定</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'selectPeople',
  props: {
    title: {
      type: String,
      default() {
        return ''
      },
    },
  },
  data() {
    return {
      isShow: false, // 是否打开弹窗
      text: '',
      data: [
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1',
                },
                {
                  id: 10,
                  label: '三级 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1',
            },
            {
              id: 6,
              label: '二级 2-2',
            },
          ],
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1',
            },
            {
              id: 8,
              label: '二级 3-2',
            },
          ],
        },
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1',
                },
                {
                  id: 10,
                  label: '三级 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1',
            },
            {
              id: 6,
              label: '二级 2-2',
            },
          ],
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1',
            },
            {
              id: 8,
              label: '二级 3-2',
            },
          ],
        },
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1',
                },
                {
                  id: 10,
                  label: '三级 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1',
            },
            {
              id: 6,
              label: '二级 2-2',
            },
          ],
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1',
            },
            {
              id: 8,
              label: '二级 3-2',
            },
          ],
        },
        {
          id: 1,
          label: '一级 1',
          children: [
            {
              id: 4,
              label: '二级 1-1',
              children: [
                {
                  id: 9,
                  label: '三级 1-1-1',
                },
                {
                  id: 10,
                  label: '三级 1-1-2',
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: '一级 2',
          children: [
            {
              id: 5,
              label: '二级 2-1',
            },
            {
              id: 6,
              label: '二级 2-2',
            },
          ],
        },
        {
          id: 3,
          label: '一级 3',
          children: [
            {
              id: 7,
              label: '二级 3-1',
            },
            {
              id: 8,
              label: '二级 3-2',
            },
          ],
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
      },
    }
  },
  methods: {
    open: function () {
      this.isShow = true
    },
    close: function () {
      this.isShow = false
    },
    submit: function() {

    },
    search: function () {
      this.$refs.tree.filter(this.text)
    },
    changeInput: function (e) {
      this.$forceUpdate()
    },

    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },

    resetChecked() {
      this.$refs.tree.setCheckedKeys([])
    },
  },
}
</script>

<style lang="stylus" scoped>
.search {
  margin-left: 24px;
}

.all-input {
  justify-content: space-between;

  .tree {
    align-items: flex-start;

    .big {
      height: 388px;
      border: 1px solid #DCDEE2;
      border-radius: 3px;
      overflow auto;
    }
  }
}
</style>
